import { SimpleTextKit } from "@sirius/editor-model";
import { Content } from "@tiptap/core";
import { UseEditorOptions } from "@tiptap/react";
import { useEffect } from "react";
import { MenuItem } from "swash/Menu";
import { Editor, EditorProps, useEditor } from "swash/editor";
import { FixedMenu } from "swash/editor/components/FixedMenu";
import { cn } from "swash/utils/classNames";

import { SpellCheck, TempOmega } from "@/components/icons";
import { StandaloneRichEditorTextBox } from "@/components/rich-editor/RichEditorTextBox";
import { useHasExperimentalFeature } from "@/containers/User";

import { renderEmojiSuggestionList, useEmojiQuery } from "./extensions/emoji";
import { MerciAppSpellCheckControl } from "./extensions/merciapp";
import { ProlexisSpellCheckControl } from "./extensions/prolexis";
import { SpecialCharControl } from "./extensions/special-characters";

/**
 * This component is used to switch between next gen simple text editor and the current.
 * @TODO Remove this component once the next gen simple text editor is stable.
 */
export const SimpleTextEditorSwitcher = ({
  showTools,
  readOnly,
  rich,
  plugins,
  w,
  ...props
}: SimpleTextEditorSwitcherProps) => {
  const hasExperimentalFeature = useHasExperimentalFeature(
    "next-gen-simple-text-editor",
  );

  const Component = hasExperimentalFeature
    ? SimpleTextEditor
    : StandaloneRichEditorTextBox;

  if (!hasExperimentalFeature) {
    props = {
      //@ts-expect-error temporary code
      showTools,
      readOnly,
      rich,
      plugins,
      variant: props.appearance,
      stripPastedStyles: rich,
      w,
      ...props,
    };
  }

  return <Component {...props} />;
};

export interface SimpleTextEditorSwitcherProps extends SimpleTextEditorProps {
  showTools?: boolean;
  readOnly?: boolean;
  rich?: boolean;
  plugins?: any;
  w?: number;
}

export const SimpleTextEditor = ({
  name,
  value = null,
  disabled,
  multiline,
  placeholder,
  onChange,
  autoFocus = false,
  children,
  rows = multiline ? 3 : undefined,
  maxRows = multiline ? 5 : undefined,
  invalid,
  ...props
}: SimpleTextEditorProps) => {
  const { data: emojis } = useEmojiQuery();

  const editor = useEditor(
    {
      editable: !disabled,
      content: value,
      onUpdate: ({ editor }) => {
        if (value !== editor.getText()) {
          onChange?.(editor.getText());
        }
      },
      extensions: [
        SimpleTextKit.configure({
          multiline,
          placeholder: {
            placeholder,
          },
          emoji: {
            emojis,
            suggestion: {
              render: renderEmojiSuggestionList,
            },
          },
        }),
      ],
      autofocus: autoFocus,
    },
    [emojis],
  );

  useEffect(() => {
    // If editor has focus, then we ignore input value
    // this component cannot be controlled while the user is editing.
    if (!editor || editor.isDestroyed || editor.isFocused) return;
    const internalValue = editor.getText();
    if (value !== internalValue) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  if (!emojis) return null;

  return (
    <Editor
      appearance="textBox"
      editor={editor}
      rows={rows}
      maxRows={maxRows}
      {...props}
      className={cn("max-w-full", props.className)}
      intent={invalid ? "danger" : props.intent}
    >
      <FixedMenu hideOnBlur={props.appearance === "inline"}>
        <MerciAppSpellCheckControl
          render={
            <MenuItem>
              <SpellCheck /> Corriger le texte avec MerciApp
            </MenuItem>
          }
        />
        <ProlexisSpellCheckControl
          render={
            <MenuItem>
              <SpellCheck /> Corriger le texte avec Prolexis
            </MenuItem>
          }
        />
        <SpecialCharControl
          render={
            <MenuItem hideOnClick={false}>
              <TempOmega /> Insérer un caractère spécial
            </MenuItem>
          }
        />
      </FixedMenu>
      {children}
    </Editor>
  );
};

export interface SimpleTextEditorProps
  extends Omit<EditorProps, "value" | "autoFocus" | "onChange"> {
  /**
   * The name of the field.
   * @example "myField"
   */
  name: string;

  /**
   * The value of the field.
   * @default null
   * @example "Hello, world!"
   */
  value?: Content;

  /**
   * Control whether the field is disabled or not.
   * @default false
   * @example true
   */
  disabled?: boolean;

  /**
   * Control whether the field is multiline or not.
   * @default false
   * @example true
   */
  multiline?: boolean;

  /**
   * Whether the editor should be focused when the component is mounted.
   */
  autoFocus?: UseEditorOptions["autofocus"];

  /**
   * The placeholder text.
   */
  placeholder?: string;

  /**
   * Callback when the field value changes.
   */
  onChange?: (value: string) => void;

  /**
   * Whether the field is invalid or not.
   */
  invalid?: boolean;
}
