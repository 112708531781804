import { RichTextKit } from "@sirius/editor-model";
import { ComponentPropsWithoutRef, FC, FocusEvent, useRef } from "react";
import { useField } from "react-final-form";
import { Editor, EditorProvider } from "swash/editor";
import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
} from "swash/editor/components/Toolbar";
import { cn } from "swash/utils/classNames";
import { useLiveRef } from "swash/utils/useLiveRef";

import { BlockquoteControl } from "@/components/editor/controls/Blockquote";
import { BoldControl } from "@/components/editor/controls/Bold";
import { BulletListControl } from "@/components/editor/controls/BulletList";
import { HeadingControl } from "@/components/editor/controls/Heading";
import { ItalicControl } from "@/components/editor/controls/Italic";
import { OrderedListControl } from "@/components/editor/controls/OrderedList";
import { RedoControl } from "@/components/editor/controls/Redo";
import { StrikethroughControl } from "@/components/editor/controls/Strike";
import { SubscriptControl } from "@/components/editor/controls/Subscript";
import { SuperscriptControl } from "@/components/editor/controls/Superscript";
import { UnderlineControl } from "@/components/editor/controls/Underline";
import { UndoControl } from "@/components/editor/controls/Undo";
import {
  renderEmojiSuggestionList,
  useEmojiQuery,
} from "@/components/editor/extensions/emoji";
import { MerciAppSpellCheckControl } from "@/components/editor/extensions/merciapp";
import { ProlexisSpellCheckControl } from "@/components/editor/extensions/prolexis";
import {
  FrequentlyUsedControl,
  SpecialCharControl,
} from "@/components/editor/extensions/special-characters";
import {
  convertContentToRichTextNode,
  convertRichTextNodeToContent,
} from "@/components/editor/utils/converter";
import { FieldControl } from "@/components/fields/FieldControl";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useFieldState } from "@/components/fields/FieldState";
import { SpellCheck, TempOmega } from "@/components/icons";
import { RichEditor } from "@/components/rich-editor/RichEditor";
import { useRichEditorState } from "@/components/rich-editor/RichEditorState";
import { RichEditorToolbar } from "@/components/rich-editor/RichEditorToolbar";
import { fromNodes } from "@/components/rich-editor/convert/fromNodes";
import { toNodes } from "@/components/rich-editor/convert/toNodes";
import { SpellCheckProvider } from "@/components/rich-editor/plugins/spell-check-control/SpellCheckPluginContext";
import { useEnhancedState } from "@/components/rich-editor/utils/useEnhancedState";
import { useBlockTemplates } from "@/containers/BlockTemplates";
import { useHasExperimentalFeature } from "@/containers/User";
import { useRichEditorPreset } from "@/containers/editor/presets/preset-rich-editor";

const toContentState = (value: any, plugins: any) =>
  fromNodes(value || [], plugins);

export const DetachedEditor: FC<InnerEditorProps> = (props) => (
  <SpellCheckProvider>
    <InnerEditor {...props} />
  </SpellCheckProvider>
);

const InnerEditor: FC<InnerEditorProps> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  blockTemplates,
  disabled,
  readOnly,
  ...props
}) => {
  const plugins = useRichEditorPreset({
    blocks: false,
    headerThree: false,
  });
  const editingRef = useRef(false);
  const onChangeRef = useLiveRef(onChange);

  const [contentState, setContentState] = useEnhancedState(
    () => toContentState(value, plugins),
    (nextState: any) => {
      if (onChangeRef.current) {
        onChangeRef.current(toNodes(nextState));
      }
    },
  );

  const editor = useRichEditorState({
    contentState,
    setContentState,
    plugins,
    blockTemplates,
    readOnly: readOnly || disabled,
  });

  const handleFocus = (evt: FocusEvent<HTMLDivElement>) => {
    if (onFocus) onFocus(evt);
    if (editingRef.current) return;
    editingRef.current = true;
  };

  const handleBlur = (evt: FocusEvent<HTMLDivElement>) => {
    if (onBlur) onBlur(evt);
    editingRef.current = false;
  };

  return (
    <div {...props}>
      <div className="m-4">
        <RichEditorToolbar
          {...editor}
          // @ts-expect-error js is not typed
          onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            editor.lockFocus();
          }}
        />
      </div>
      <RichEditor
        {...editor}
        // @ts-expect-error js is not typed
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

interface InnerEditorProps extends ComponentPropsWithoutRef<"div"> {
  name: string;
  label?: string;
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;
  placeholder?: string;
  blockTemplates?: any;
  disabled?: boolean;
  readOnly?: boolean;
}

export function useRichTextEditorField(
  name: string,
  {
    required = false,
    id,
    orientation = "vertical",
    format,
    ...options
  }: UseRichTextEditorFieldOptions = {},
) {
  const field = useField(name, {
    format,
    ...options,
  });
  return useFieldState({
    field,
    id,
    orientation,
    required,
  });
}

interface UseRichTextEditorFieldOptions {
  required?: boolean;
  id?: string;
  orientation?: "vertical" | "horizontal";
  format?: any;
  [key: string]: any;
}

const RichTextEditor = ({
  value,
  placeholder = "Contenu...",
  disabled,
  invalid,
  onChange,
  // @FIXME: blockTemplates is not used for now
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  blockTemplates = [],
  ...props
}: RichTextEditorProps) => {
  const { data: emojis } = useEmojiQuery();
  const extensions = [
    RichTextKit.configure({
      placeholder: { placeholder },
      emoji: {
        emojis,
        suggestion: {
          render: renderEmojiSuggestionList,
        },
      },
    }),
  ];
  const content = value
    ? convertRichTextNodeToContent(value, extensions)
    : null;

  const handleUpdate = ({ editor }: { editor: Editor }) => {
    onChange(convertContentToRichTextNode(editor.getJSON(), extensions));
  };

  return (
    <div {...props} className={cn("w-full", props.className)}>
      <EditorProvider
        editable={!disabled}
        extensions={extensions}
        content={content}
        onUpdate={handleUpdate}
      >
        <Toolbar>
          <HeadingControl level={2} />
          <BulletListControl />
          <OrderedListControl />
          <BlockquoteControl />
          <ToolbarSeparator />
          <SpecialCharControl
            render={
              <ToolbarItem title="Insérer un caractère spécial">
                <TempOmega />
              </ToolbarItem>
            }
          />
          <FrequentlyUsedControl />
        </Toolbar>
        <Toolbar>
          <UndoControl />
          <RedoControl />
          <ToolbarSeparator />
          <MerciAppSpellCheckControl
            render={
              <ToolbarItem title="Corriger le texte avec MerciApp">
                <SpellCheck />
              </ToolbarItem>
            }
          />
          <ProlexisSpellCheckControl
            render={
              <ToolbarItem title="Corriger le texte avec Prolexis">
                <SpellCheck />
              </ToolbarItem>
            }
          />
          <ToolbarSeparator />
          <BoldControl />
          <ItalicControl />
          <UnderlineControl />
          <StrikethroughControl />
          <SubscriptControl />
          <SuperscriptControl />
        </Toolbar>
        <Editor intent={invalid ? "danger" : undefined} scale="base" />
      </EditorProvider>
    </div>
  );
};

interface RichTextEditorProps extends ComponentPropsWithoutRef<"div"> {
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  value: any;
  onChange: (value: any) => void;
  blockTemplates?: any;
}

export const RichTextField: FC<RichTextFieldProps> = ({
  name,
  label,
  hint,
  placeholder,
  disabled,
  readOnly,
  ...options
}) => {
  const field = useRichTextEditorField(name, options);
  const blockTemplates = useBlockTemplates();
  const fieldProps = {
    ...field,
    placeholder,
    disabled,
    readOnly,
    blockTemplates,
  };
  const hasExperimentalFeature = useHasExperimentalFeature(
    "next-gen-rich-text-fields",
  );
  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FieldControl
        as={hasExperimentalFeature ? RichTextEditor : DetachedEditor}
        {...fieldProps}
        className="rounded-sm border border-grey-border-light bg-white"
      />
    </FieldGroup>
  );
};

interface RichTextFieldProps {
  name: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  [key: string]: any;
}
