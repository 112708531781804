import { ApolloClient, useApolloClient } from "@apollo/client";
import { Button } from "swash/Button";
import { DialogDisclosure, DialogStore, useDialogStore } from "swash/Dialog";
import { useToaster } from "swash/Toast";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useStoreState } from "swash/utils/useStoreState";

import { ConfirmDialog, ConfirmDialogBody } from "@/components/ConfirmDialog";
import { FORM_ERROR, extractGraphQlErrors } from "@/components/forms/Form";
import { useAsyncData } from "@/services/hooks/useAsyncData";

export function EnableDisableButton<TNode extends { enabled: boolean }>({
  node,
  setEnabled,
  confirmTitle,
  confirmMessage,
  confirmDisabled,
  hasConfirmationText,
}: {
  node: TNode;
  setEnabled: (enabled: boolean) => Promise<void>;
  confirmTitle: string;
  confirmMessage: React.ReactNode;
  confirmDisabled?: (context: {
    client: ApolloClient<any>;
    node: NoInfer<TNode>;
  }) => {
    disabled: boolean;
    disabledMessage: React.ReactNode;
  };
  hasConfirmationText?: boolean;
}) {
  const toaster = useToaster();

  const dialog = useDialogStore();

  const onConfirm = useEventCallback(async () => {
    try {
      await setEnabled(!node.enabled);
    } catch (error) {
      const graphQLErrors = extractGraphQlErrors(error);
      const errorMessage = graphQLErrors[FORM_ERROR];
      toaster.danger(errorMessage);
    }
  });

  if (!node.enabled) {
    return (
      <Button type="button" variant="success" onClick={onConfirm}>
        Activer
      </Button>
    );
  }

  return (
    <>
      <DialogDisclosure
        store={dialog}
        render={
          <Button type="button" variant="secondary">
            Désactiver
          </Button>
        }
      />
      <EnableDisableConfirmDialog
        store={dialog}
        node={node}
        confirmTitle={confirmTitle}
        confirmMessage={confirmMessage}
        confirmDisabled={confirmDisabled}
        hasConfirmationText={hasConfirmationText}
        onConfirm={onConfirm}
      />
    </>
  );
}

function EnableDisableConfirmDialog<TNode>({
  store,
  node,
  confirmTitle,
  confirmMessage,
  confirmDisabled,
  hasConfirmationText,
  onConfirm,
}: {
  store: DialogStore;
  node: TNode;
  confirmTitle: string;
  confirmMessage: React.ReactNode;
  confirmDisabled?: (context: {
    client: ApolloClient<any>;
    node: NoInfer<TNode>;
  }) => {
    disabled: boolean;
    disabledMessage: React.ReactNode;
  };
  hasConfirmationText?: boolean;
  onConfirm: () => Promise<void>;
}) {
  const open = useStoreState(store, "open");
  const client = useApolloClient();
  const { data } = useAsyncData(async () => {
    if (!open) return;
    return confirmDisabled?.({ client, node });
  }, [open, node]);

  return (
    <ConfirmDialog
      store={store}
      onConfirm={async () => {
        await onConfirm();
      }}
      title={confirmTitle}
      confirmButtonLabel="Désactiver"
      confirmationText={hasConfirmationText ? "DESACTIVER" : undefined}
      disabled={data?.disabled}
    >
      <ConfirmDialogBody>
        {data?.disabled ? data.disabledMessage : confirmMessage}
      </ConfirmDialogBody>
    </ConfirmDialog>
  );
}
